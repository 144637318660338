<template>
  <div class="loading-container">
    <moon-loader :loading="loading" :color="color" :size="size"></moon-loader>
  </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader";

export default {
  components: {
    MoonLoader
  },
  data() {
    return {
      color: "rgba(63, 87, 223, 0.6)",
      size: "100px"
    };
  },
  props: {
    loading: Boolean
  }
};
</script>

<style>
.loading-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
}
</style>