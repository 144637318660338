<template>
  <div>
    <AlertDialog
      :show="showErr"
      type="failed"
      :msg="errMsg"
      @close="showErr = false"
    />
    <AlertDialog
      :show="showSuccess"
      type="success"
      :msg="successMsg"
      @close="showSuccess = false"
    />
    <HeaderLayout :env="env" :type="0" />

    <b-container class="mt--5">
      <b-row class="justify-content-center">
        <b-col lg="7" class="text-center">
          <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
        </b-col>
      </b-row>
      <p>QR code result: {{ qrResult }}</p>
      <p>Error: {{ qrError }}</p>
    </b-container>

    <FooterLayout :env="env" />
  </div>
</template>

<script>
import HeaderLayout from "../../view/HeaderLayout";
import FooterLayout from "../../view/FooterLayout";
import AlertDialog from "../../view/dialog/AlertDialog";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: {
    QrcodeStream,
    HeaderLayout,
    FooterLayout,
    AlertDialog,
  },
  data() {
    return {
      qrResult: "",
      qrError: "",
      showSuccess: false,
      successMsg: "",
      showErr: false,
      errMsg: "",
    };
  },
  props: {
    env: Object,
  },
  mounted() {
    // const resultLink =
    //   "http://192.168.0.102:8080/main?type=visitor&invitation=AA000003A";
    // const resURL = new URL(resultLink);
    // // console.log(resURL, currURL);
    // const resHost = resURL.protocol + "//" + resURL.hostname;
    // if (resHost != this.env.SERVER_URL) {
    //   this.showErr = true;
    //   this.errMsg = this.$t("validation.qr-code");
    //   return;
    // }
    // const resQueryObj = this.getJsonFromUrl(resURL.search);
    // // console.log(resQueryObj);
    // if (resQueryObj.type == "visitor") {
    //   // console.log("visitor");
    //   const key = Object.keys(resQueryObj);
    //   if (!key.includes("invitation")) {
    //     this.showErr = true;
    //     this.errMsg = this.$t("validation.qr-code");
    //     return;
    //   } else {
    //     this.showSuccess = true;
    //     this.successMsg = this.$t("entry.qr-code");
    //     this.$router.replace({ path: "main", query: resQueryObj });
    //   }
    // } else if (resQueryObj.type == "resident") {
    //   // console.log("resident");
    //   const key = Object.keys(resQueryObj);
    //   if (
    //     !key.includes("otp") ||
    //     key.includes("unitId") ||
    //     key.includes("email")
    //   ) {
    //     this.showErr = true;
    //     this.errMsg = this.$t("validation.qr-code");
    //     return;
    //   } else {
    //     this.showSuccess = true;
    //     this.successMsg = this.$t("entry.qr-code");
    //     this.$router.push({ path: "main", query: resQueryObj });
    //   }
    // } else {
    //   this.showErr = true;
    //   this.errMsg = this.$t("validation.qr-code");
    //   return;
    // }
  },
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.qrError = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.qrError = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.qrError = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.qrError = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.qrError = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.qrError = "ERROR: Stream API is not supported in this browser";
        }
      }
    },

    onDecode(result) {
      this.qrResult = result;

      const resultLink = result;
      //  const resultLink =
      //     "http://192.168.0.102:8080/main?type=visitor&invitation=AA000003A";

      const resURL = new URL(resultLink);

      // console.log(resURL, currURL);
      const resHost = resURL.protocol + "//" + resURL.hostname;

      if (resHost != this.env.SERVER_URL) {
        this.showErr = true;
        this.errMsg = this.$t("validation.qr-code");
        return;
      }

      const resQueryObj = this.getJsonFromUrl(resURL.search);

      // console.log(resQueryObj);

      if (resQueryObj.type == "visitor") {
        // console.log("visitor");

        const key = Object.keys(resQueryObj);

        if (!key.includes("invitation")) {
          this.showErr = true;
          this.errMsg = this.$t("validation.qr-code");
          return;
        } else {
        //   this.showSuccess = true;
        //   this.successMsg = this.$t("entry.qr-code");

          this.$router.replace({ path: "main", query: resQueryObj });
        }
      } else if (resQueryObj.type == "resident") {
        // console.log("resident");

        const key = Object.keys(resQueryObj);

        if (
          !key.includes("otp") ||
          key.includes("unitId") ||
          key.includes("email")
        ) {
          this.showErr = true;
          this.errMsg = this.$t("validation.qr-code");
          return;
        } else {
        //   this.showSuccess = true;
        //   this.successMsg = this.$t("entry.qr-code");

          this.$router.push({ path: "main", query: resQueryObj });
        }
      } else {
        this.showErr = true;
        this.errMsg = this.$t("validation.qr-code");
        return;
      }
    },
    getJsonFromUrl(url) {
      var query = url.substr(1);
      var result = {};
      query.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    },
  },
};
</script>