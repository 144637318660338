<template>
  <div id="mainSection">
    <div
      id="section1"
      v-if="showSection1"
      style="
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
      "
    >
      <span v-if="hasError">
        Something went wrong<br />
        Please come back again later
      </span>
    </div>
    <div id="section2" v-if="showSection2" style="word-break: break-all">
      <div id="articleSection">
        <h1
          :style="`border-left: 8px solid ${
            colorCodeList[(postCategoryId - 1) % colorCodeList.length]
          }; word-break: break-word`"
        >
          <span class="prefix"></span>{{ postTitle }}
        </h1>
        <hr />
        <div class="padding-post pb-2">
          <i>
            <small>
              <div>
                {{ $t("announcement.category") }}<b>: {{ postCategory }}</b>
              </div>
              {{ $t("announcement.by") }}<b>: {{ createdBy }}</b> /
              {{ createdOn | formatDate }}
            </small>
          </i>
        </div>
      </div>
      <div style="margin-top: 6px">
        <img class="w-100" :src="coverImage" />
      </div>
      <div
        id="contentSection"
        class="hyphens-text padding-post"
        style="word-break: break-word"
        v-html="postContent"
      ></div>
      <div id="attachmentSection">
        <p
          style="margin-top: -4px"
          v-for="(post, index) in postAttachment"
          :key="`post-${index}`"
        >
          <a :href="post" download>
            <md-icon style="font-size: 15px !important">attach_file</md-icon
            ><b>{{ $t("announcement.attachment") }} {{ index + 1 }}</b>
          </a>
        </p>
      </div>
      <hr />
      <div id="viewNLikeSection">
        <div class="padding-post">
          <span @click="likeOrDislikeBtnOnClick()" style="cursor: pointer">
            <md-icon class="text-blue" v-if="isLiked"> favorite </md-icon>
            <md-icon v-if="!isLiked"> favorite_border </md-icon>
            <span v-if="totalLike == 0"></span>
            <span v-if="totalLike == 1">
              {{ $t("announcement.likedBy")
              }}<b>{{ totalLike }} {{ $t("announcement.person") }}</b
              ><span style="margin-left: 16px; margin-right: 16px"
                >|</span
              ></span
            >
            <span v-if="totalLike > 1">
              {{ $t("announcement.likedBy")
              }}<b>{{ totalLike }} {{ $t("announcement.people") }}</b
              ><span style="margin-left: 16px; margin-right: 16px"
                >|</span
              ></span
            >
          </span>
          <b v-if="totalView < 2">
            {{ totalView }} {{ $t("announcement.view") }}</b
          >
          <b v-if="totalView > 1">
            {{ totalView }} {{ $t("announcement.views") }}</b
          >
        </div>
        <div></div>
      </div>
      <div v-if="gotoURL != ''" id="gotoSection" style="text-align: center">
        <button
          mat-button
          class="btn btn-primary new2"
          @click="gotoBtnOnclick()"
        >
          {{ $t("announcement.moreDetails") }}
        </button>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import i18n from "../../i18n";

export default {
  components: {},
  data() {
    // initial variables
    return {
      showSection1: true,
      showSection2: false,
      hasError: false,
      id: 0,
      token: "",
      colorCodeList: [
        "#8a2be2",
        "#ff4040",
        "#000080",
        "#8b0000",
        "#0e2f44",
        "#008000",
        "#420420",
        "#065535",
        "#f7347a",
        "#ffa500",
      ],
      pageTitle: "Loading...",
      postTitle: "Loading...",
      postCategory: "Loading...",
      postCategoryId: 0,
      createdBy: "Loading...",
      createdOn: "Loading...",
      coverImage: "",
      postContent: "Loading...",
      postAttachment: [],
      gotoURL: "",
      totalView: 0,
      totalLike: 0,
      isLiked: false,
    };
  },
  created() {
    // onInit
    this.$loading.show({ delay: 0 }); //delay 0ms, default is 300ms
    document.title = this.pageTitle;
    const urlInfo = this.$route.query;
    this.id = urlInfo.id;
    this.token = urlInfo.token;
    this.getPostInfo();
  },
  props: {
    env: Object,
  },
  computed: {
    // when something changed, will call here
  },
  methods: {
    // onclick those function
    autoGoToURL() {
      // if(!window.open(this.gotoURL, '_blank')) {
      window.location.href = this.gotoURL;
      // }
    },
    gotoBtnOnclick() {
      this.$swal({
        icon: "warning",
        title: this.$t("announcement.holdOn"),
        html:
          this.$t("announcement.youAreDirectingTo") +
          "<br>" +
          this.$t("announcement.areYouSureYou"),
        showDenyButton: true,
        confirmButtonText: this.$t("announcement.back"),
        denyButtonText: this.$t("announcement.continue"),
      }).then((result) => {
        console.log({ result });
        /* Read more about isConfirmed, isDenied below */
        if (!result.isConfirmed) {
          console.log("gooooo");
          this.autoGoToURL();
        }
      });
    },
    likeOrDislikeBtnOnClick() {
      axios
        .put(
          `${this.env.SERVER_URL}/angsaku/announcement-n-community/user/announcement/like/` +
            this.id +
            "/" +
            this.token
        )
        .then(() => {
          this.isLiked = !this.isLiked;

          if (this.isLiked) {
            this.totalLike += 1;
          } else {
            this.totalLike -= 1;
          }
        })
        .catch((error) => {
          console.log("something went wrong 1 " + error);
        });
    },
    getPostInfo() {
      axios
        .get(
          `${this.env.SERVER_URL}/angsaku/announcement-n-community/user/announcement/view/` +
            this.id +
            "/" +
            this.token
        )
        .then((res) => {
          let content = res.data.content;
          console.log(content);
          this.$loading.hide();
          i18n.locale = content.userLanguage;
          localStorage.setItem("lang", content.userLanguage);
          this.showSection1 = false;
          this.showSection2 = true;
          this.hasError = false;

          this.pageTitle = content.announcementDAO.title;
          this.postTitle = content.announcementDAO.title;
          this.postCategory = content.announcementDAO.category;
          this.postCategoryId = content.announcementDAO.categoryId;
          this.createdBy = content.announcementDAO.postBy;
          this.createdOn = content.announcementDAO.startDate;
          this.coverImage = content.announcementDAO.coverImage;
          this.postContent = content.announcementDAO.description;

          this.postAttachment = [];
          for (var i = 0; i < content.attachmentList.length; i++) {
            this.postAttachment.push(content.attachmentList[i].url);
          }
          // this.postAttachment = content.;

          var url = content.announcementDAO.url;
          // if ((!url.includes("http") || !url.includes("https")) && url !== "") {
          //   url = `http://${url}`;
          // }
          this.gotoURL = url.trim();
          this.totalView = content.totalViews;
          this.totalLike = content.totalLike;
          this.isLiked = content.like;
          document.title = this.pageTitle;
        })
        .catch((error) => {
          console.log("something went wrong 2 " + error);
          this.$loading.hide();
          this.hasError = true;
        });
    },
  },
};
</script>
<style>
#contentSection figure {
  width: 100% !important;
}

.hyphens-text {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

#articleSection h1 {
  padding: 0;
  padding-left: 0px;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 2rem;
  text-align: left;
  padding-left: 10px;
  margin-top: 4px;
}

.w-100 {
  width: 100% !important;
}

#contentSection {
  margin-top: 20px;
  word-break: normal;
}

#attachmentSection {
  margin-top: 32px;
}

#gotoSection {
  margin-top: 32px;
  margin-bottom: 12px;
}

@media screen and (min-width: 1024px) {
  #mainSection {
    max-width: 964px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

#viewNLikeSection {
  margin-top: 24px;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.padding-post {
  padding-right: 10px;
  padding-left: 10px;
}
</style>
