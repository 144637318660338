<template>
  <div class="header bg-translucent-primary">
    <b-navbar toggleable="lg" type="dark">
      <div class="container">
        <b-navbar-nav v-if="back">
          <b-nav-item>
            <md-button class="md-icon-button" @click="$router.go(-1)">
               <md-icon class="text-white">arrow_back</md-icon>
            </md-button>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Not yet translate so hide first -->
        <!-- <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <md-menu md-align-trigger>
              <md-button class="md-icon-button" md-menu-trigger>
                <md-icon class="text-white">g_translate</md-icon>
              </md-button>

              <md-menu-content>
                <span class="language">{{ $t("header.lang") }}</span>

                <md-menu-item @click="changeLanguage('en')">
                  <span>English</span>
                </md-menu-item>

                <md-menu-item @click="changeLanguage('zh')">
                  <span>中文</span>
                </md-menu-item>

                <md-menu-item @click="changeLanguage('ms')">
                  <span>Bahasa Melayu</span>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </b-nav-item>
        </b-navbar-nav> -->
      </div>
    </b-navbar>

    <b-container class="header-padding" @click="window.location.reload(true)">
      <div class="header-body text-center">
        <b-row class="justify-content-center">
          <b-col lg="8" md="8">
            <h2 class="text-white header-title" >
              {{ projectName || env.COMP_NAME }}
            </h2>

            <h1 class="text-white">{{ headerType }}</h1>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon class="fill-default" points="2560 0 2560 100 0 100" />
      </svg>
    </div>
  </div>
</template>

<script>
import i18n from "../i18n";
import axios from "axios";
export default {
  props: {
    env: Object,
    type: Number,
    back: Boolean
  },
  data() {
    return {
      headerType: "",
      projectName: ""
    }
  },
  created() {
    // onInit
    this.headerType = this.type == 0 ? this.$t("header.security") : this.type == 1 ? this.$t("header.visitor") : this.type == 2 ? this.$t("header.facility") : this.$t("header.family");
    console.log("type: ", this.type + " || 0: Security, 1: Visitor, 2: Facility, 3: Family");
    this.getCompany();
  },
  methods: {
    changeLanguage(lang) {
      // window.location.reload(true);
      i18n.locale = lang;
      localStorage.setItem("lang", lang);
    },
    getCompany() {
      axios
        .get(
          `${this.env.SERVER_URL}/angsaku/multi-unit/settings/admin/system-info?type=COMPANY_NAME`
        )
        .then((res) => {
          if (res) {
            if (res.data.content.length > 0) {
              res.data.content.forEach(info => {
                if (info.type == 'COMPANY_NAME') {
                  this.projectName = info.value;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log("something went wrong 1 " + error);
        });
    }
  },
  //Before creating, check if there is any value in localStore and if not, then use the system language.
  beforeCreate() {
    if (localStorage.getItem("lang") == null) {
      let lng = window.navigator.systemLanguage || window.navigator.language;
      lng = lng.substr(0, 2).toLowerCase();
      localStorage.setItem('language', lng);
      this.$i18n.locale = lng;
    }
  },
};
</script>

<style>
.bg {
  background-image: url("../assets/images/background/visitor.jpg");
  background-size: cover;
  background-position: center top;
}

.language {
  padding: 4px 12px;
  font-weight: 800;
  font-size: 12px;
}

.header-padding {
  padding-top: 3rem;
  padding-bottom: 8rem;
}

@media only screen and (max-width: 767px) {
  /* .header-title {
    font-size: 22px;
  } */

  .header-padding {
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
}
</style>