
import { register } from 'register-service-worker';
import alertify from "alertifyjs";

function notifyUserAboutUpdate() {
  alertify.alert()
    .setting({
      'title': "Latest version available",
      'label': 'Refresh now',
      'message': 'We have upgraded the system to serve you better! Refresh to get the latest version. Don\'t worry, the process will only take a few seconds.',
      'onok': function () { window.location.reload(true); }
    }).show();
}

// Prevent browser prompt to install (PWA)
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
});

// if ('serviceWorker' in navigator) {
window.addEventListener('load', () => {
  console.log('SW loaded: ');
  if (navigator.serviceWorker) {
    console.log("Navigator has service worker initialized...")
    var refreshing = false;
    console.log("Refreshing var:", refreshing);
    navigator.serviceWorker.addEventListener("controllerchange", function () {
      console.log("Refreshing var:", refreshing);

      if (refreshing) return;
      window.location.reload(true);
      refreshing = true;
    });

  } else {
    console.log("Nagivator don't have service worker...")
  }
});
// }

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB'
    )

  },
  registered() {
    console.log('Service worker has been registered.')
  },
  cached() {
    console.log('Content has been cached for offline use.')
  },
  updatefound() {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName);
      });
    });
    // alert('New content is downloading.')

    console.log('New content is downloading.')
  },
  updated() {
    console.log('New content is available; please refresh.')
    notifyUserAboutUpdate();
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  }
});