var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-dialog',{attrs:{"md-active":_vm.show},on:{"update:mdActive":function($event){_vm.show=$event},"update:md-active":function($event){_vm.show=$event}}},[_c('div',{staticClass:"d-flex justify-content-center icon-space",style:({
      'background-color':
        _vm.type == 'success'
          ? '#2DCE89'
          : _vm.type == 'warning'
          ? '#ECBE7A'
          : '#E58B88',
    })},[_c('div',{staticClass:"d-flex h-100"},[(_vm.type == 'success')?_c('img',{staticClass:"my-auto",attrs:{"src":require("@/assets/images/alert/check.png"),"width":"80","height":"80"}}):(_vm.type == 'warning')?_c('img',{staticClass:"my-auto",attrs:{"width":"80","height":"80"}}):_c('img',{staticClass:"my-auto",attrs:{"src":require("@/assets/images/alert/error.png"),"width":"80","height":"80"}})])]),_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"d-flex flex-row mb-0"},[_c('div',{staticClass:"d-flex flex-column w-100 text-center justify-content-center align-middle mx-auto"},[_c('h3',{staticClass:"title font-weight-bold justify-content-center mb-2 mt-2"},[(_vm.type == 'success')?_c('div',[_vm._v(_vm._s(_vm.$t("success")))]):(_vm.type == 'warning')?_c('div',[_vm._v(_vm._s(_vm.$t("warning")))]):_c('div',[_vm._v(_vm._s(_vm.$t("failed")))])]),_c('h3',{staticClass:"msg",domProps:{"innerHTML":_vm._s(_vm.msg)}}),(_vm.countDown)?_c('div',{staticClass:"text-small"},[_vm._v(_vm._s(_vm.$t("redirecting")))]):_vm._e()])])]),(!_vm.countDown)?_c('div',{staticClass:"d-flex action-button mb-2"},[_c('button',{staticClass:"btn btn-primary",attrs:{"mat-button":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])]):_vm._e(),(_vm.countDown)?_c('div',{staticClass:"d-flex action-button mb-2"},[_c('button',{staticClass:"btn btn-primary",attrs:{"mat-button":""},on:{"click":function($event){return _vm.closeWindow()}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ("+_vm._s(_vm.countSec)+") ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }