<template>
  <div>
    <HeaderLayout :env="env" :type="3" />

    <div class="container mt--7">
      <div class="col-md-12 col-lg-10 col-centered bg-white">
        <div class="title-border"></div>
        <div
          class="mt-3 d-flex justify-content-center"
          v-for="(form, index) in formState"
          :key="index"
        >
          <div class="row" style="width: 100%" v-if="state == form.state">
            <div class="col-md-6 title my-auto">
              <h1>{{ form.title }}</h1>
              <div v-if="details">
                <p class="mb-2">
                  {{ $t("response.details-title") }}
                  <span class="h4"> {{ details.ownerNickname }}</span
                  >.
                </p>
                <p class="mb-0">{{ $t("response.home-address") }}:</p>
                <p class="h4">
                  {{ details.blockName }}, {{ details.street }}, <br />
                  {{ details.city }},<br />
                  {{ details.state }}, {{ details.country }}.
                </p>
              </div>
              <div v-else>
                <label class="form-control-label form-desc">{{form.label}}&nbsp;</label><br />
              </div>

            </div>
            <div class="col-md-6 form-image">
              <img :src="form.image" />
            </div>
          </div>
        </div>
      </div>
      <FooterLayout class="footer-res" :env="env" />
    </div>
  </div>
</template>

<script>
import FooterLayout from "../../view/FooterLayout";
import HeaderLayout from "../../view/HeaderLayout";
import completedSVG from "../../assets/images/background/completed-animate.svg";
import NotFoundSVG from "../../assets/images/background/page-not-found-animate.svg";
import AloneSVG from "../../assets/images/background/alone-animate.svg";
import axios from "axios";

export default {
  components: {
    FooterLayout,
    HeaderLayout,

  },
  props: {
    env: Object,
  },
  data() {
    return {
      type: 0,
      details: "",
      state: "",
      formState: [
        {
          state: "alreadyresponded",
          title: this.$t("response.responded-title") ,
          label: `${this.$t("response.responded-label")}`,
          image: completedSVG,
        },
        {
          state: "not-found",
          title: this.$t("response.not-found-title"),
          label: this.$t("response.not-found-label"),
          image: NotFoundSVG,
        },
        {
          state: "rejected",
          title: this.$t("response.rejected"),
          label: '',
          image: AloneSVG,
        }
      ],
    };
  },
  created() {
    if (sessionStorage.getItem("details")) {
      this.details = JSON.parse(sessionStorage.getItem("details"));
      sessionStorage.removeItem('details');
    }
    this.state = this.$route.query.state;
    const isExist = this.formState.findIndex(item => item.state == this.state);
    if (isExist == -1) {
      this.state = "not-found";
    }
    this.getPhoneNumber(this.state);

  },

  methods:{
    getPhoneNumber(responseStatus){
      axios
      .get(
        `${this.env.SERVER_URL}/angsaku/community/setting/visitor`
      )
      .then((res) => {
         const phoneNumber = `(${res.data.content.managerCountry})-${res.data.content.managerContact}`
         const index = this.formState.findIndex(state => state.state == responseStatus);

         if (index != -1 && this.state != "not-found" && this.state != "rejected") this.formState[index].label = `${this.$t("response.responded-label")} ${phoneNumber}`;
      });
    }

  },
  beforeDestroy() {
    sessionStorage.removeItem("details");
  },
};
</script>

<style>
.footer-res {
  clear: both;
  position: relative;
  margin-top: -20px;
}

.col-centered {
  float: none;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.title-border {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: -webkit-calc(100%);
  width: calc(100%);
  top: 0;
  left: 0;
  position: absolute;
  bottom: -1px;
  height: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #3f51b5;
}

.form-desc {
  font-weight: 100;
}

.language {
  padding: 4px 12px;
  font-weight: 800;
  font-size: 12px;
}

.header-padding {
  padding-top: 3rem;
  padding-bottom: 8rem;
}

@media only screen and (max-width: 990px) {
  .footer-res {
    margin-top: 200px;
  }

  h1 {
    text-align: center;
  }

  .form-desc {
    margin: 0 auto;
    display: table;
  }
}

@media only screen and (max-width: 600px) {
  .footer-res {
    margin-top: 5px;
  }

  h1 {
    text-align: center;
  }

  .title {
    padding-top: 10%;
  }

  .form-desc {
    margin: 0 auto;
    display: table;
    text-align: center;
  }

  .form-image {
    padding-bottom: 5%;
  }
}
</style>