<template>
  <footer class="py-5">
    <b-container>
      <b-row class="align-items-center justify-content-xl-between">
        <b-col xl="6">
          <div
            class="copyright text-center text-xl-left text-muted"
          >{{ companyName }}</div>
          <!-- &copy; {{ currentYear }} {{ companyName }}</div> -->
        </b-col>
        <b-col xl="6">
          <ul class="nav nav-footer justify-content-center justify-content-xl-end">
            <li class="nav-item" @click="openPP()">
              <a class="nav-link" target="_blank" tabindex="0">Privacy Policy</a>
            </li>
            <li class="nav-item" @click="openTOS()">
              <a class="nav-link" target="_blank" tabindex="0">Terms of Service</a>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import moment from 'moment';

export default {
  methods: {
    openPP() {
      window.open(this.pp)
    },
    openTOS() {
      window.open(this.tos)
    }
  },
  data() {
    return {
      companyName: "",
      pp: "",
      tos: "",
      currentYear: moment().format("YYYY")
    }
  },
  props: {
    env: Object
  },
  created() {
    this.companyName = this.env.FOOTER_NAME;
    this.pp = this.env.PP
    this.tos = this.env.TOS
  }
};
</script>

<style>
</style>