<template>
  <div v-if="env != null">
    <router-view :env="env" />
  </div>
</template>

<script>
import axios from "axios";
import enviroment from "./assets/env.json"

export default {
  name: "App",
  data() {
    return {
      env: null
    };
  },
  beforeCreate() {
    axios
      .get("/env.json")
      .then(res => {
        this.env = res.data;
      })
      .catch(() => {
        this.env = enviroment;
      });
  }
};
</script>

