<template>
  <div>
    <AlertDialog
      :show="showErr"
      type="failed"
      :msg="errMsg"
      @close="showErr = false"
    />
    <AlertDialog
       v-if="submitted"
      :show="showSuccess"
      :countDown="true"
      type="success"
      :msg="successMsg"
      @close="showSuccess = false"
    />

    <HeaderLayout :env="env" :type="0" :back="true" />
    <b-container class="mt--5">
      <b-row>
        <b-col lg="6" class="mb-3">
          <b-card class="shadow h-100">
            <table class="visitor-table w-100">
              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.status") }}</th>
                <td class="w-5">:</td>
                <td>
                  <span class="badge badge-dot">
                    <i
                      :class="
                        status == 'Expired' || status == 'Revoked'
                          ? 'bg-danger'
                          : status == 'Active'
                          ? 'bg-success'
                          : 'bg-primary'
                      "
                    ></i>
                  </span>
                  {{ statusName }}
                </td>
              </tr>

              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.type") }}</th>
                <td class="w-5">:</td>
                <td>{{ type }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.invId") }}</th>
                <td class="w-5">:</td>
                <td>#{{ invitation }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.fromDate") }}</th>
                <td class="w-5">:</td>
                <td>{{ fromDate }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.toDate") }}</th>
                <td class="w-5">:</td>
                <td>{{ toDate }}</td>
              </tr>
            </table>

            <hr />

            <h3>{{ $t("vstr-rsdnt.ownerTitle") }}</h3>

            <table class="visitor-table w-100">
              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.unitName") }}</th>
                <td class="w-5">:</td>
                <td>{{ unitName }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.fullName") }}</th>
                <td class="w-5">:</td>
                <td>{{ fullName }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.nickName") }}</th>
                <td class="w-5">:</td>
                <td>{{ nickname }}</td>
              </tr>
            </table>

            <b-row class="mt-4">
              <b-col>
                <b-button
                  block
                  variant="primary"
                  @click="check('IN')"
                  :disabled="status != 'Active' || disableCheckIn"
                  >{{ $t("vstr-rsdnt.checkInBtn") }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  block
                  variant="danger"
                  @click="check('OUT')"
                  :disabled="status != 'Active' || disableCheckOut"
                  >{{ $t("vstr-rsdnt.checkOutBtn") }}</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col lg="6" class="mb-3">
          <b-card class="shadow h-100">
            <h3>{{ $t("vstr-rsdnt.visitorTitle") }}</h3>

            <table class="visitor-table w-100">
              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.email") }}</th>
                <td class="w-5">:</td>
                <td>{{ email }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t("vstr-rsdnt.carPlate") }}</th>
                <td class="w-5">:</td>
                <td>{{ carPlateNo }}</td>
              </tr>
            </table>

            <hr />

            <div class="scroll-container">
              <div v-for="(visitor, index) in visitorList" :key="index">
                <h3>{{ $t("vstr-rsdnt.visitor") }} {{ index + 1 }}</h3>

                <table class="visitor-table w-100 mb-3">
                  <tr
                    v-for="(
                      addInfo, index
                    ) in visitor.visitorAdditionalInfoDAOList"
                    :key="index"
                  >
                    <th class="w-30">{{ addInfo.ctValue }}</th>
                    <td class="w-5">:</td>
                    <th>{{ addInfo.value }}</th>
                  </tr>
                </table>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <FooterLayout :env="env" />
  </div>
</template>

<script>
import HeaderLayout from "../../view/HeaderLayout";
import FooterLayout from "../../view/FooterLayout";
import AlertDialog from "../../view/dialog/AlertDialog";
import axios from "axios";

export default {
  components: {
    HeaderLayout,
    FooterLayout,
    AlertDialog,
  },
  data() {
    return {
      status: "",
      type: "",
      invitation: "",
      fromDate: "",
      toDate: "",

      unitName: "",
      fullName: "",
      nickname: "",

      email: "",
      carPlateNo: "",
      invId : "",
      icNo: "",

      disableCheckIn: false,
      disableCheckOut: false,

      countDown: false,
      showSuccess: false,
      successMsg: "",
      showErr: false,
      errMsg: "",
      submitted: false,

      visitorList: [],
    };
  },
  props: {
    env: Object,
  },
  created() {
    if (this.$route.query.info) {
      this.info = JSON.parse(this.$route.query.info);

      console.log("info",this.info.id);

      this.status = this.info.status;
      this.type = this.info.type;
      this.fromDate = this.info.startDate;
      this.toDate = this.info.endDate;
      this.invitation = this.info.invitationId;
      this.invId = this.info.id;

      this.fullName = this.info.ownerInfo.fullName;
      this.nickname = this.info.ownerInfo.nickName;
      this.unitName = this.info.fullBlockName;

      this.email = this.info.visitorEmail ? this.info.visitorEmail : '-';
      this.carPlateNo = this.info.carPlateNo ? this.info.carPlateNo : '-';

      this.visitorList = this.info.additionalInfoList;
    }
  },
  computed: {
    statusName() {
      switch (this.status) {
        case "Pending":
          return this.$t("vstr-rsdnt.pending");
        case "Active":
          return this.$t("vstr-rsdnt.active");
        case "Revoked":
          return this.$t("vstr-rsdnt.revoked");
        case "Expired":
          return this.$t("vstr-rsdnt.expired");

        default:
          return this.status;
      }
    },
  },
  methods: {
    check(type) {
      const body = {
        type: type,
        invitationId: this.invId,
      };

      if (type == "IN") {
        this.disableCheckIn = true;
      } else {
        this.disableCheckOut = true;
      }

      axios
        .post(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/user/entry-record`,
          body
        )
        .then(() => {
          // const content = res.data.content;

          this.submitted = true;
          this.showSuccess = true;
          this.countDown = true;

          if (type == "IN") {
            this.successMsg = this.$t("inOut.in");
          } else {
            this.successMsg = this.$t("inOut.out");
          }
        })
        .catch((error) => {
          // alert(error);

          if (type == "IN") {
            this.disableCheckIn = false;
          } else {
            this.disableCheckOut = false;
          }

          const code = error.response.data.content;

          if (!code) {
            this.showErr = true;
            this.errMsg = this.$t(`error.code.unknown`);
            return;
          }

          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    },
  },
};
</script>

<style>
h3 {
  margin-bottom: 12px !important;
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.scroll-container {
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>