<template>
  <div>
    <HeaderLayout :env="env" :type="2" />

    <div class="container mt--7">
      <div class="col-md-12 col-lg-10 col-centered bg-white">
        <div class="title-border"></div>
        <div
          class="mt-3 d-flex justify-content-center"
          v-for="(form, index) in formState"
          :key="index"
        >
          <div class="row" style="width: 100%" v-if="state == form.state">
            <div class="col-md-6 title my-auto">
              <h1>{{ form.title }}</h1>
              <div>
                <label class="form-control-label form-desc text-muted"
                  >{{ form.label }}&nbsp;</label
                >
                <br />
              </div>
              <p v-if="details" class="mb-1 details-title">
                {{ $t("facility.title") }}:
              </p>
              <div class="form-details" v-if="details">
                <div>{{ $t("facility.date") }}</div>
                :
                <div>
                  <b class="attention">{{ date }}</b>
                </div>
                <div>{{ $t("facility.facility") }}</div>
                :
                <div>
                  <b class="attention">{{ facilityName }}</b
                  ><br />
                  ({{ courts.join(", ") }})
                </div>
              </div>
            </div>
            <div class="col-md-6 form-image">
              <img :src="form.image" />
            </div>
          </div>
        </div>
      </div>
      <FooterLayout class="footer-res" :env="env" />
    </div>
  </div>
</template>

<script>
import FooterLayout from "../../view/FooterLayout";
import HeaderLayout from "../../view/HeaderLayout";
import axios from "axios";
import loadingSVG from "../../assets/images/background/loading.svg";
import successSVG from "../../assets/images/background/success-checkin.svg";
import unabledSVG from "../../assets/images/background/unabled.svg";
import NoDataSVG from "../../assets/images/background/no-data.svg";
import moment from "moment";
export default {
  methods: {
    checkInFacility(fatId, token) {
      axios
        .get(
          `${this.env.SERVER_URL}/angsaku/facility-management/user/check-in/${fatId}/${token}`
        )
        .then((res) => {
          console.log({ res });
          this.state = "success";
        })
        .catch((error) => {
          try {
            const code = error.response.data.content;

            // Unable to check in now
            if (code == "E010000027") {
              this.state = "failed";
              if (error.response.data.content2) {
                this.details = true;
                const data = error.response.data.content2;
                this.facilityName = data.facilityName;
                this.date = moment(data.startDate).format("D MMM YYYY h:mm a");
                data.bookingCourtSlotList.map((item) => {
                  let temp = item.courtSlotList.map((list) => list.courtName);
                  this.courts.push(...temp);
                });
                return;
              }
            }

            // No booking found
            if (code == "E010000028") {
              this.state = "booking-not-found";
              return;
            }

            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }

            // User not found
            this.state = "user-not-found";
          } catch (e) {
 
            this.showErr = true;
            this.errMsg = this.$t(`error.code.unknown`);
            // User not found
            this.state = "user-not-found";
          }
        });
    },
  },
  components: {
    FooterLayout,
    HeaderLayout,
  },
  props: {
    env: Object,
  },
  created() {
    this.state = "check-in";
    const id = this.$route.query.id;
    const token = this.$route.query.token;
    this.checkInFacility(id, token);
  },
  data() {
    return {
      state: "",
      details: false,
      date: "",
      facilityName: "",
      courts: [],
      formState: [
        {
          state: "check-in",
          title: this.$t(`facility.checkin-title`),
          label: this.$t(`facility.checkin-label`),
          image: loadingSVG,
        },
        {
          state: "success",
          title: this.$t(`facility.success-title`),
          label: this.$t(`facility.success-label`),
          image: successSVG,
        },
        {
          state: "failed",
          title: this.$t(`facility.unable`),
          label: this.$t(`error.code.E010000027`),
          image: unabledSVG,
        },
        {
          state: "user-not-found",
          title: this.$t(`facility.failed-title`),
          label: this.$t(`error.code.E001001002`),
          image: NoDataSVG,
        },
        {
          state: "booking-not-found",
          title: this.$t(`facility.failed-title`),
          label: this.$t(`error.code.E010000028`),
          image: NoDataSVG,
        },
      ],
    };
  },
};
</script>

<style>
.footer-res {
  clear: both;
  position: relative;
  margin-top: -20px;
}

.col-centered {
  float: none;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.title-border {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: -webkit-calc(100%);
  width: calc(100%);
  top: 0;
  left: 0;
  position: absolute;
  bottom: -1px;
  height: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #3f51b5;
}

.form-desc {
  font-weight: 100;
}

.form-details {
  display: grid;
  grid-template-columns: 50px 10px auto;
  font-size: 0.875rem;
}

.attention {
  font-size: 1rem !important;
}

.language {
  padding: 4px 12px;
  font-weight: 800;
  font-size: 12px;
}

.header-padding {
  padding-top: 3rem;
  padding-bottom: 8rem;
}

@media only screen and (max-width: 990px) {
  .footer-res {
    margin-top: 200px;
  }

  h1 {
    text-align: center;
  }

  .form-desc {
    margin: 0 auto;
    display: table;
  }
}

@media only screen and (max-width: 600px) {
  .footer-res {
    margin-top: 5px;
  }

  h1 {
    text-align: center;
  }

  .title {
    padding-top: 10%;
  }

  .form-desc {
    margin: 0 auto;
    display: table;
    text-align: center;
  }

  .form-image {
    padding-bottom: 5%;
  }

  .form-details {
    justify-content: center;
  }

  .details-title {
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }
}
</style>