import Vue from 'vue';
import Router from 'vue-router';

// import Main from './components/main/Main.vue';
import Entry from './components/auth/Entry.vue';
import Register from './components/auth/Register.vue'
import GuardRegister from './components/auth/GuardRegister.vue'
import Visitor from './components/visitor/Visitor.vue'
import Resident from './components/resident/Resident.vue'
import CopyLink from './components/copy-link/CopyLink.vue'
import QRCode from './components/qr-code/QRCode.vue'
import Scan from './components/scan/Scan.vue'
import Post from './components/announcement/Post.vue'
import Invitation from './components/invitation-link/InvitationLink.vue'
import Responses from './components/response/Responses.vue';
import QrCodeCheckIn from './components/facility/QrCodeCheckIn.vue';
// import Test from './components/Test.vue'
// import Test2 from './components/Test2.vue'
// import Test4 from './components/Test4.vue'


Vue.use(Router);

const routes = [
    {
        path: '/',
        component: Entry,
        props: true
    },
    {
        path: '/main',
        component: Entry,
        props: true
    },
    {
        path: '/register',
        component: Register,
        props: true
    },
    {
        path: '/g-register',
        component: GuardRegister,
        props: true
    },
    {
        path: '/visitor',
        component: Visitor
    },
    {
        path: '/resident',
        component: Resident
    },
    {
        path: '/copy-link',
        component: CopyLink
    },
    {
        path: '/qr-code',
        component: QRCode
    },
    {
        path: '/scan',
        component: Scan
    },
    {
        path: '/post',
        component: Post
    },
    {
        path: '/member',
        component: Invitation
    },
    {
        path: '/response',
        component: Responses
    },
    {
        path: '/facility-checkin',
        component: QrCodeCheckIn
    }
    // {
    //     path: '/test',
    //     component: Test4
    // },
    // {
    //     path: '/test2',
    //     component: Test2
    // },
];


export default new Router({
    mode: 'history',
    routes,
});