<template>
  <div>
    <HeaderLayout :env="env" :type="1" />
    <b-container class="mt--5">
      <b-row class="justify-content-center">
        <b-col lg="6" class="mb-3">
          <b-card no-body class="shadow h-100">
            <b-card-body class="text-center">
              <h4>
                {{ $t("qr.security") }}
              </h4>

              <vue-qrcode :value="link" class="qr-code" />

              <h4>
                <span class="inv-fw">{{ $t("vstr-rsdnt.invId") }}: </span><span class="inv-id">#{{ invitationId }}</span>
              </h4>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <FooterLayout :env="env" />
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import HeaderLayout from "../../view/HeaderLayout"
import FooterLayout from "../../view/FooterLayout"

export default {
  components: {
    VueQrcode,
    HeaderLayout,
    FooterLayout
  },
  data() {
    return {
      link: "",
      invitation: "",
      invitationId: "",
    };
  },
  props: {
    env: Object
  },
  created() {
    this.invitation = this.$route.query.invitation;
    this.invitationId = this.$route.query.invitationId;
    const result = this.env.QR_CODE_SERVER_URL;
    console.log("invitation",this.invitation);
    console.log("invitationId",this.invitationId);

    this.link = `${result}/main?type=visitor&invitation=${this.invitation}&invitationId=${this.invitationId}`;

    
  },
};
</script>

<style>
.qr-code {
  width: 80% !important;
  height: 80% !important;
  max-width: 200px  !important;
  max-height: 200px !important;
}

.inv-fw {
  font-weight: 400;
}

.inv-id {
  font-weight: 700;
}
</style>