<template>
  <div>
    <b-container fluid>
      <b-row class="justify-content-center">
        <b-col lg="12" class="px-0">
          <b-card-img
            v-if="advList.length != 0"
            :src="advList[0].image"
            @click="toUrl(advList[0])"
            alt="Image"
            width="563"
            height="155"
            style="height: 107px !important"
            top
          ></b-card-img>
        </b-col>
      </b-row>
    </b-container>
    <HeaderLayout :env="env" :type="1" />

    <b-container class="mt--5">
      <b-row class="justify-content-center">
        <b-col lg="6" class="mb-3">
          <b-card no-body class="shadow h-100">
            <b-card-body class="text-center">
              <h4 v-if="email != ''">
                {{ $t("qr.title") }}
                <br />
                <span class="text-primary">{{ email }}</span>
              </h4>

              <!-- <vue-qrcode :value="link" /> -->
              <h4 class="my-4">{{ $t("qr.content") }}</h4>
              <vue-qrcode id="inviteQrcode" :value="link" class="qr-code" />
              <br />
              <!-- <a class="text-primary" :href="link" target="_blank">
                <b>{{ link }}</b>
              </a> -->
              <p v-if="invitationId">#{{ invitationId }}</p>

              <b-row class="mt-4">
                <b-col>
                  <b-button v-if="!isAngsakuApp()" block variant="primary" @click="downloadQrCode()">
                    {{ $t("qr.save-qr") }}
                  </b-button>

                  <b-button v-if="isAngsakuApp()" block variant="primary" @click="openApp()">
                    {{ $t("open_app") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <FooterLayout :env="env" />
    <b-container fluid>
      <b-row class="justify-content-center">
        <b-col lg="12" class="px-0">
          <b-card-img
            v-if="advList.length != 0"
            :src="advList[1].image"
            width="563"
            @click="toUrl(advList[1])"
            alt="Image"
            style="height: 107px !important"
            bottom
          ></b-card-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import HeaderLayout from "../../view/HeaderLayout";
import FooterLayout from "../../view/FooterLayout";
import axios from "axios";
import html2canvas from "html2canvas";
import moment from "moment";
// import VueQrcode from "vue-qrcode";

export default {
  components: {
    VueQrcode,
    HeaderLayout,
    FooterLayout,
    // VueQrcode
  },
  data() {
    return {
      email: "",
      link: "",
      invitation: "",
      invitationId: "",
      advList: [],
      userAgent: "",
    };
  },
  created() {
    // this.checkfilledorunfilled()
    this.getAdv();
    this.email = this.$route.query.email;
    this.invitation = this.$route.query.invitation;
    this.invitationId = this.$route.query.invitationId;
    this.link = this.invitation;
    if(!this.isAngsakuApp() && this.$route.query.appState != 'openGuardApp') {
      this.openApp()
    }
  },
  props: {
    env: Object,
  },
  methods: {
    checkfilledorunfilled() {
      //       window.popStateDetected = false
      // window.addEventListener('popstate', () => {
      //   window.popStateDetected = true
      // })
      // router.beforeEach((to, from, next) => {
      //   const IsItABackButton = window.popStateDetected
      //   // console.log("IsItABackButton",IsItABackButton)
      //   window.popStateDetected = false
      //   if (IsItABackButton && from.meta.someLogica) {
      //     next(false)
      //     return ''
      //   }
      //   next()
      // })
      // axios
      //   .get(
      //     `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/visitor/qr-code-valid/${this.invitation}`,
      //     body
      //   )
      //   .then(() => {}).catch((error) => {
      //       // alert(error);
      //       reject();
      //       const code = error.response.data.content;
      //       this.errMsg = this.$t(`error.code.${code}`);
      //       if (!code) {
      //         this.showErr = true;
      //         this.errMsg = this.$t(`error.code.unknown`);
      //         return;
      //       }
      //       this.showErr = true;
      //       this.errMsg = this.$t(`error.code.${code}`);
      //     });
    },
    // copy() {
    //   let textarea;

    //   try {
    //     textarea = document.createElement("textarea");
    //     textarea.setAttribute("readonly", true);
    //     textarea.setAttribute("contenteditable", true);
    //     textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
    //     textarea.value = this.link;

    //     document.body.appendChild(textarea);

    //     // textarea.focus();
    //     textarea.select();

    //     const range = document.createRange();
    //     range.selectNodeContents(textarea);

    //     const sel = window.getSelection();
    //     sel.removeAllRanges();
    //     sel.addRange(range);

    //     textarea.setSelectionRange(0, textarea.value.length);
    //     document.execCommand("copy");

    //     alert(this.$t("qr.copylink"));
    //   } catch (err) {
    //     console.error(err);
    //   } finally {
    //     document.body.removeChild(textarea);
    //   }

    //   return true;
    // },
    downloadQrCode() { 
      window.scrollTo(0, 0);
      this.loading = true;
      var data = document.getElementById("inviteQrcode");
      var today = new Date();
      var formatDate = moment(today).format("MM-DD-YYYY-hh-mm");
      html2canvas(data).then((canvas) => {
        this.loading = false;
        if (this.invitationId) {
          formatDate += `_${this.invitationId}`;
        }
        canvas.toBlob(function (blob) {
          // To download directly on browser default 'downloads' location
          let link = document.createElement("a");
          link.download = `QRcode_${formatDate}.png`;
          link.href = URL.createObjectURL(blob);
          link.click();
        }, "image/png");
      });
    },
    getAdv() {
      axios
        .get(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/advertise/visitor`
        )
        .then((res) => {
          console.log(res);
          // let tempLink = process.env.VUE_APP_API_VISITOR.replace(
          //   "/visitor-management",
          //   ""
          // );

          this.advList = res.data.content;
          // .content.map((data) => {
          //   data.image = data.image;

          //   return data;
          // });

          if (this.advList.length == 1) {
            this.advList.push(this.advList[0]);
          }
        })
        .catch((error) => {
          // alert(error);
          const code = error.response.data.content;

          if (!code) {
            this.showErr = true;
            this.errMsg = this.$t(`error.code.unknown`);
            return;
          }

          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    },
    toUrl(adv) {
      window.open(adv.url);
    },
    isAngsakuApp () {
      return this.userAgent.includes('angsakuApp');
    },
    openApp() {

      if (this.isAngsakuApp()) return;

      this.userAgent = navigator.userAgent;
      const query = { ...this.$route.query, appState: 'openGuardApp' };
      this.$router.push({
        path: "/copy-link",
        query: query,
      });
      window.location.reload();
      // alert(this.$route.fullPath);
    }
  },
  mounted() {
    // document.addEventListener("backbutton", this.checkfilledorunfilled, false);
    // console.log("backbtn:",document.addEventListener("backbutton", this.checkfilledorunfilled, false))
    // window.onpopstate = function(event) {
    //   alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
    // };

    window.onbeforeunload = function () {
      return alert("Your work will be lost.");
    };
    this.userAgent = navigator.userAgent;

  },
};
</script>