<template>
  <div>
    <HeaderLayout :env="env" :type="3" />
    <b-container class="mt--7">
      <b-row class="justify-content-center">
        <b-col lg="6" class="mb-3" v-if="details">
          <b-card no-body class="shadow h-100">
            <b-card-body class="text-center">
              <div class="d-flex mx-auto" style="width: 100px; height: 100px; border-radius: 50%; overflow: hidden;">
                <img
                  :src="details.profileImage
                          ? details.profileImage
                          : '@/assets/images/background/Image-not-found.png'"
                  alt="invite-image"
                  style="object-fit: cover; width: 100%;"
                />
              </div>

              <h2 class="mt-3 mb-1">{{ details.ownerNickname }}</h2>
              <p class="mb-0">
                {{ $t("invitation.title") }}
              </p>
              <!-- <h2 class="mb-0">{{ details.blockName }},</h2> -->
              <h4 class="mt-2">
                {{ details.blockName }}, {{ details.street }}, <br />
                {{ details.city }},<br />
                {{ details.state }}, {{ details.country }}.
              </h4>

              <div class="d-flex justify-content-center mt-4">
                <b-button variant="primary" @click="updateInvitation(1)">{{
                  $t("invitation.joinNow")
                }}</b-button>
                <b-button variant="danger" @click="updateInvitation(-1)">{{
                  $t("invitation.reject")
                }}</b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <FooterLayout :env="env" />
  </div>
</template>

<script>
import HeaderLayout from "../../view/HeaderLayout";
import FooterLayout from "../../view/FooterLayout";
import axios from "axios";

export default {
  components: {
    HeaderLayout,
    FooterLayout,
  },
  data() {
    return {
      invitation: "",
      details: "",
    };
  },
  props: {
    env: Object,
  },
  methods: {
    getInvitationInfo() {
      axios
        .get(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API}/tenant-management/user/get-member-invitation-info?id=${this.invitation}`
        )
        .then((res) => {
          this.details = res.data.content;
        })
        .catch((error) => {
          // alert(error);

          const code = error.response.data.content;

          if (!code) {
            this.showErr = true;
            this.errMsg = this.$t(`error.code.unknown`);
            return;
          }

          if (code == "E003000018") {
            this.$router.push({
              path: "/response",
              query: { state: "rejected" },
            });
          }

          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    },
    updateInvitation(status) {
      axios
        .put(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API}/tenant-management/user/accept-or-deny-member-invitation?id=${this.invitation}&status=${status}`
        )
        .then((res) => {
          if (res.status == 200 && status == 1) {
            sessionStorage.setItem("details", JSON.stringify(this.details));
          }
          this.$router.push({
              path: "/response",
              query: { state: status == 1 ? "alreadyresponded" : "rejected"},
            });
        })
        .catch((error) => {
          // alert(error);

          const code = error.response.data.content;
          if (!code) {
            this.showErr = true;
            this.errMsg = this.$t(`error.code.unknown`);
            return;
          }

          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    },
  },
  created() {
    this.invitation = this.$route.query.invitation;
    this.getInvitationInfo();
  },
};
</script>

<style>
.qr-code {
  width: 80% !important;
  height: 80% !important;
  max-width: 200px !important;
  max-height: 200px !important;
}

.inv-fw {
  font-weight: 400;
}

.inv-id {
  font-weight: 700;
}
</style>