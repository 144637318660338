import Vue from 'vue';
import router from './router';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import PortalVue from 'portal-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/app.scss'
import i18n from './i18n'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import axios from 'axios';
// import VueCrypt from 'vue-crypt'
// import VeeValidate from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import moment from 'moment';
import Dialog from 'vue-dialog-loading';
import './registerServiceWorker';
import 'alertifyjs/build/alertify.min.js';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
// import VConsole from "vconsole";
// import { initPlugin } from 'vue-vconsole-devtools'


Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(PortalVue)
Vue.use(VueMaterial)
Vue.use(VueSweetalert2)
Vue.use(Dialog, {
  dialogBtnColor: '#0f0'
 })

const session = new Vuex.Store({
  state() {
    return {
      info: ""
    }
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
});

Vue.use(session)
// Vue.use(VueCrypt)
// Vue.use(VeeValidate);

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded;charset=utf-8";

Vue.config.productionTip = false

new Vue({
  router,
  axios,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY');
  }
})
// console.log("process.env.SHOW_VCONSOLE:", process.env.SHOW_VCONSOLE)

// if (process.env.SHOW_VCONSOLE == "TRUE") {
//   initPlugin(new VConsole());

//   // 在创建跟实例以后调用， 需要借助webpack的异步模块加载能力
//   if(process.env.NODE_ENV === "development"){
//     Promise.all([import("vconsole"), import("vue-vconsole-devtools")]).then(
//       (res) => {
//         if (res.length === 2) {
//           Vue.config.devtools = true;
//           window.__VUE_DEVTOOLS_GLOBAL_HOOK__.emit("init",Vue)
//           const VConsole = res[0].default;
//           const Devtools = res[1].default;
//           Devtools.initPlugin(new VConsole());
//         }
//       }
//     );
//   }
// }

