import Vue from 'vue'
import VueI18n from 'vue-i18n'
import $ from 'jquery'

Vue.use(VueI18n)

const lang = localStorage.getItem("lang");
// Original code (Load from http)//
function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  console.log("locales", locales.keys())
  const messages = {}
  locales.keys().forEach(async key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = await getMessages(key)
    }
  })
  return messages
}

function getMessages(key) {
  const path = key.replace("./", "")
  return new Promise((resolve) => {
    $.get(`locales/${path}`, function(data) {
      resolve(data)
    })
  })
}

export default new VueI18n({
  locale: lang ? lang : process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: lang ? lang : process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

// Original code (Load from local)//
// import Vue from 'vue'
// import VueI18n from 'vue-i18n'

// Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
