<template>
  <div>
    <AlertDialog :show="showErr" type="failed" :msg="errMsg" @close="showErr = false" />
    <AlertDialog v-if="submitted" :show="showSuccess" type="success" :countDown="true" :msg="successMsg" @close="showSuccess = false" />

    <HeaderLayout :env="env" :type="0" :back="true" />

    <b-container class="mt--5">
      <b-row class="justify-content-center">
        <b-col lg="6" class="mb-3">
          <b-card class="shadow h-100">
            <table class="visitor-table w-100">
              <tr>
                <th class="w-30">{{ $t('vstr-rsdnt.status') }}</th>
                <td class="w-5">:</td>
                <td>
                  <span class="badge badge-dot">
                    <!-- <i :class="status == -1 ? 'bg-danger' : 'bg-success'"></i> -->

                    <i
                      :class="
                      status == -1 || status == -2 ? 'bg-danger' : 
                      status == 1 ||  status == 2 ? 'bg-success' :
                    'bg-primary'"
                    ></i>
                  </span>
                  {{ statusName }}
                </td>
              </tr>

              <tr>
                <th class="w-30">{{ $t('vstr-rsdnt.type') }}</th>
                <td class="w-5">:</td>
                <td>{{ type }}</td>
              </tr>
            </table>

            <hr />

            <h3>{{ $t('vstr-rsdnt.residentTitle') }}</h3>

            <table class="visitor-table w-100">
              <tr>
                <th class="w-30">{{ $t('vstr-rsdnt.unitName') }}</th>
                <td class="w-5">:</td>
                <td>{{ unitName }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t('vstr-rsdnt.fullName') }}</th>
                <td class="w-5">:</td>
                <td>{{ fullname }}</td>
              </tr>

              <tr>
                <th class="w-30">{{ $t('vstr-rsdnt.nickName') }}</th>
                <td class="w-5">:</td>
                <td>{{ nickname }}</td>
              </tr>
            </table>

            <b-row class="mt-4">
              <b-col>
                <b-button
                  block
                  variant="primary"
                  @click="check('IN')"
                  :disabled="status != 1 && status != 2 || disableCheckIn"
                >{{ $t('vstr-rsdnt.checkInBtn') }}</b-button>
              </b-col>
              <b-col>
                <b-button
                  block
                  :disabled="status != 1 && status != 2 || disableCheckOut"
                  variant="danger"
                  @click="check('OUT')"
                >{{ $t('vstr-rsdnt.checkOutBtn') }}</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <FooterLayout :env="env" />
  </div>
</template>

<script>
import HeaderLayout from "../../view/HeaderLayout";
import FooterLayout from "../../view/FooterLayout";
import AlertDialog from "../../view/dialog/AlertDialog";

import axios from "axios";

export default {
  components: {
    HeaderLayout,
    FooterLayout,
    AlertDialog
  },
  data() {
    return {
      info: null,
      status: null,
      type: "",
      unitName: "",
      fullname: "",
      nickname: "",

      userId: "",
      unitId: "",

      disableCheckIn: false,
      disableCheckOut: false,
      countDown: false,
      showSuccess: false,
      successMsg: "",
      showErr: false,
      errMsg: "",
      submitted: false
    };
  },
  computed: {
    statusName() {
      switch (this.status) {
        case 0:
          return this.$t("vstr-rsdnt.pending");
        case 1:
          return this.$t("vstr-rsdnt.available");
        case 2:
          return this.$t("vstr-rsdnt.available");
        case -1:
          return this.$t("vstr-rsdnt.revoked");
        case -2:
          return this.$t("vstr-rsdnt.expired");

        default:
          return this.status;
      }
    }
  },
  props: {
    env: Object
  },
  created() {
    if (this.$route.query.info) {
      this.info = JSON.parse(this.$route.query.info);

      this.status = this.info.status;
      this.type = this.info.type;
      this.nickname = this.info.nickName ? this.info.nickName : "-";
      this.fullname = this.info.fullName ? this.info.fullName : "-";
      this.unitName = this.info.fullBlockName;
      this.userId = this.info.userId;
      this.unitId = this.info.unitId;
    }
  },
  methods: {

    check(type) {
      const body = {
        type: type,
        userId: this.userId,
        unitId: this.unitId
      };

       if (type == "IN") {
        this.disableCheckIn = true;
      } else {
        this.disableCheckOut = true;
      }

      axios
        .post(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/user/entry-record`,
          body
        )
        .then(() => {
          // const content = res.data.content;
          this.submitted = true;
          this.showSuccess = true;
          this.countDown = true;

          if (type == "IN") {
            this.successMsg = this.$t("inOut.in");
          } else {
            this.successMsg = this.$t("inOut.out");
          }
        })
        .catch(error => {
          // alert(error);
           if (type == "IN") {
            this.disableCheckIn = false;
          } else {
            this.disableCheckOut = false;
          }
          
          const code = error.response.data.content;

          if(!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }

          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    }
  }
};
</script>

<style>
h3 {
  margin-bottom: 12px !important;
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
</style>