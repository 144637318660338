<template>
  <md-dialog :md-active.sync="show">
    <div
      class="d-flex justify-content-center icon-space"
      :style="{
        'background-color':
          type == 'success'
            ? '#2DCE89'
            : type == 'warning'
            ? '#ECBE7A'
            : '#E58B88',
      }"
    >
      <div class="d-flex h-100">
        <img
          class="my-auto"
          v-if="type == 'success'"
          src="@/assets/images/alert/check.png"
          width="80"
          height="80"
        />
        <img
          class="my-auto"
          v-else-if="type == 'warning'"
          src="@/assets/images/alert/danger.png"
          width="80"
          height="80"
        />
        <img
          class="my-auto"
          v-else
          src="@/assets/images/alert/error.png"
          width="80"
          height="80"
        />
      </div>
    </div>

    <div class="dialog-content">
      <div class="d-flex flex-row mb-0">
        <div
          class="d-flex flex-column w-100 text-center justify-content-center align-middle mx-auto"
        >
          <h3 class="title font-weight-bold justify-content-center mb-2 mt-2">
            <div v-if="type == 'success'">{{ $t("success") }}</div>
            <div v-else-if="type == 'warning'">{{ $t("warning") }}</div>
            <div v-else>{{ $t("failed") }}</div>
          </h3>

          <h3 v-html="msg" class="msg"></h3>
        </div>
      </div>
    </div>

    <div class="d-flex action-button mb-2">
      <button class="btn" @click="$emit('cancel')">
        {{ $t("Cancel") }}
      </button>
      <button class="btn btn-primary" @click="$emit('onOkay')">
        {{ $t("ok") }}
      </button>
    </div>
  </md-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    show: Boolean,
    type: String,
    msg: String,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style>
.icon-space {
  width: 100%;
  height: 150px;
}

.md-dialog-container {
  min-width: 0 !important;
  width: 80% !important;
  max-width: 600px !important;
  padding: 0 !important;
  border-radius: 15px 50px !important;
  height: auto !important;
  max-height: 500px !important;
}

.title {
  font-size: 20px !important;
}

.action-button {
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.action-button button {
  border-radius: 5px;
  padding: 5px 20px;
  width: 40%;
  margin: 10px 0;
  font-weight: 500;
}

.action-button button + .btn-primary:hover {
  /* background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%); */
  /* color: white !important; */
  transition: 0.8s;
}

.dialog-content {
  height: auto;
  max-height: 400px;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .icon-space {
    width: 100%;
    height: 100px;
  }

  .msg {
    font-size: 15px !important;
  }

  .dialog-content {
    height: auto;
    max-height: 250px;
    overflow-y: auto;
  }
}
</style>