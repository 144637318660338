<template>
  <div>
    <LoadingLayout :loading="spinner" />

    <AlertDialog
      :show="showErr"
      type="failed"
      :msg="errMsg"
      @close="showErr = false"
    />

    <div v-if="!spinner">
      <HeaderLayout :env="env" :type="1" />
      <b-container class="mt--5">
        <b-row class="justify-content-center">
          <b-col col lg="6" md="9">
            <b-card class="shadow">
              <table class="visitor-table w-100 mb-3">
                <tr >
                  <th class="w-30">{{ $t("register.invId") }}</th>
                  <td class="w-5">:</td>
                  <td>#{{ invitation }}</td>
                </tr>

                <tr>
                  <th class="w-30">{{ $t("register.dateTime") }}</th>
                  <td class="w-5">:</td>
                  <td>{{ startDate }}</td>
                </tr>

                <tr>
                  <th class="w-30">{{ $t("register.dateTime2") }}</th>
                  <td class="w-5">:</td>
                  <td>{{ endDate }}</td>
                </tr>

                <tr >
                  <th class="w-30">{{ $t("register.type") }}</th>
                  <td class="w-5">:</td>
                  <td>{{ type }}</td>
                </tr>
              </table>

              <hr />

              <b-form @submit.prevent="onSubmit()">
                <h3>{{ $t("vstr-rsdnt.ownerTitle") }}</h3>
                <b-form-group>
                  <label class="form-control-label">{{
                    $t("vstr-rsdnt.unitName")
                  }}</label>
                  <b-form-input
                    v-model="unitName"
                    type="text"
                    maxlength="20"
                    disabled
                    trim
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label class="form-control-label">{{
                    $t("vstr-rsdnt.fullName")
                  }}</label>
                  <b-form-input
                    v-model="ownerName"
                    type="text"
                    maxlength="60"
                    disabled
                    trim
                  ></b-form-input>
                </b-form-group>
                <hr />

                <h3>{{ $t("vstr-rsdnt.visitorTitle") }}</h3>

                <b-form-group>
                  <label class="form-control-label">
                    {{ $t("vstr-rsdnt.email") }}
                  </label>
                  <b-form-input
                    v-model="email"
                    :placeholder="$t('vstr-rsdnt.emailPh')"
                    :disabled="disabledEmail"
                    type="email"
                    maxlength="50"
                    aria-describedby="inv-pin-feedback"
                    trim
                  ></b-form-input>
                  <!-- <b-form-invalid-feedback
                    id="inv-pin-feedback"
                    >{{ invEmailMsg }}</b-form-invalid-feedback
                  > -->
                </b-form-group>

                <b-form-group>
                  <label class="form-control-label">
                    {{ $t("vstr-rsdnt.carPlate") }}&nbsp;
                  </label>
                  <b-form-input
                    v-model="carPlateNo"
                    :placeholder="$t('vstr-rsdnt.carPlatePh')"
                    :disabled="disabledCarPlate"
                    type="text"
                    maxlength="15"
                    aria-describedby="inv-pin-feedback"
                    trim
                  ></b-form-input>
                  <!-- <b-form-invalid-feedback
                    id="inv-pin-feedback"
                    >{{ invEmailMsg }}</b-form-invalid-feedback
                  > -->
                </b-form-group>

                <!-- <b-form-group>
                <label class="form-control-label">
                  Total visitor&nbsp;
                  <span class="text-danger">*</span>
                </label>

                <NumberInput
                  :value="$v.form.totalVisitor.$model"
                  :min="1"
                  :step="1"
                  @changes="setTotalVisitor"
                />
                </b-form-group>-->

                <hr />

                <div v-for="(visitor, i) in visitorList" :key="i">
                  <h3>
                    <span class="mr-3"
                      >{{ $t("vstr-rsdnt.visitor") }} {{ i + 1 }}</span
                    >

                    <b-button
                      variant="success"
                      size="sm"
                      v-if="
                        maxVisitor > 1 &&
                        i == visitorList.length - 1 &&
                        i != maxVisitor - 1
                      "
                      @click="addVisitor()"
                    >
                      <md-icon
                        class="text-white icon-size"
                        style="font-size: 14px !important"
                        >add</md-icon
                      >
                    </b-button>

                    <b-button
                      variant="danger"
                      size="sm"
                      v-if="i != 0"
                      @click="removeVisitor(i)"
                    >
                      <md-icon
                        class="text-white icon-size"
                        style="font-size: 14px !important"
                        >remove</md-icon
                      >
                    </b-button>
                  </h3>

                  <div v-for="(info, j) in visitor.addInfo" :key="i + '.' + j">
                    <b-form-group v-if="info.show">
                      <label class="form-control-label">
                        {{ info.label }}
                        <span class="text-danger" v-if="info.required"
                          >&nbsp;*</span
                        >
                      </label>

                      <div v-if="info.type == 'DATE'">
                        <b-form-datepicker
                          v-model="info.value"
                          :value="info.value"
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          trim
                        ></b-form-datepicker>
                      </div>

                      <div v-else-if="info.type == 'DATE_TIME'">
                        <b-row>
                          <b-col lg="6" class="mb-2">
                            <b-form-datepicker
                              v-model="info.date"
                              :value="info.date"
                              :date-format-options="{
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              }"
                              trim
                            ></b-form-datepicker>
                          </b-col>
                          <b-col lg="6">
                            <b-form-timepicker
                              v-model="info.time"
                              :value="info.time"
                              locale="en"
                            ></b-form-timepicker>
                          </b-col>
                        </b-row>
                      </div>

                      <div v-else-if="info.type == 'LONG_TEXT'">
                        <b-form-textarea
                          v-model="info.value"
                          :value="info.value"
                          maxlength="300"
                          rows="5"
                          trim
                        ></b-form-textarea>
                      </div>

                      <div v-else>
                        <b-form-input
                          v-model="info.value"
                          :value="info.value"
                          maxlength="50"
                          trim
                        ></b-form-input>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="mt-4">
                  <b-button
                    type="submit"
                    block
                    variant="primary"
                    :disabled="loading"
                  >
                    <b-spinner small class="mr-2" v-if="loading"></b-spinner>
                    {{ $t("register.submitBtn") }}
                  </b-button>
                </div>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <FooterLayout :env="env" />
    </div>
  </div>
</template>

<script>
import HeaderLayout from "@/view/HeaderLayout";
import FooterLayout from "@/view/FooterLayout";
import LoadingLayout from "@/view/LoadingLayout";
import AlertDialog from "@/view/dialog/AlertDialog";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    HeaderLayout,
    FooterLayout,
    LoadingLayout,
    AlertDialog,
    // NumberInput
  },
  data() {
    return {
      invitation: "",
      startDate: "",
      endDate: "",
      type: "",
      id: "",

      unitName: "",
      ownerName: "",
      maxVisitor: 1,
      disabledEmail: false,
      disabledCarPlate: false,
      visitorList: [],
      addInfo: "",
      email: "",
      carPlateNo: "",

      spinner: true,
      loading: false,

      showErr: false,
      errMsg: "",
    };
  },
  props: {
    env: Object,
  },

  created() {
    this.invitation = this.$route.query?.invitation;

  },
  async mounted() {
    await this.getSettingInfo();

    if (this.invitation) {
      this.getInfo();
    }
  },
  methods: {
     checkfilledorunfilled(){
      return new Promise((resolve, reject) => {
      axios
        .get(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/visitor/qr-code-valid/${this.id}`)
        .then((res) => {
            console.log("havent made",res);
            resolve(true);

        }).catch((error) => {
          // alert(error);
          let code = error.response.data.content;
          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
          reject(false);
        });

      });
    },
    getInfo() {
      let invitation = encodeURIComponent(this.invitation);

      this.spinner = true;
      axios
        .get(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/visitor/invitation-registry/${invitation}`
        )
        .then((res) => {
          this.spinner = false;
          let content = res.data.content;

          this.id = content.id;
          this.invitation = content.invitationId;
          this.startDate = moment(content.startDate).format(
            "D MMM YYYY h:mm a"
          );
          this.endDate = moment(content.endDate).format("D MMM YYYY h:mm a");
          this.type = content.type;

          this.unitName = content.fullBlockName;
          this.ownerName = content.ownerInfo.fullName;

          this.email = content.visitorEmail;

          this.carPlateNo = content.carPlateNo;

          if (this.email != "" && this.email != null) {
            this.disabledEmail = true;
          }

           if (this.carPlateNo != "" && this.carPlateNo != null) {
            this.disabledCarPlate = true;
          }

          // Cannot pass this.addInfo to this.visitorList, Vue's will read this.addInfo's value
          //   let addInfo = this.addInfo.map((data) => {
          //     data.value = "";
          //     data.date = "";
          //     data.time = "";
          //     return data;
          //   });

          let addInfo = this.formatAddInfo();

          this.visitorList.push({ addInfo: addInfo });
        })
        .catch((error) => {
          // alert(error);
          let code = error.response.data.content;
          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    },
    getSettingInfo() {
      return new Promise((resolve, reject) => {
        this.spinner = true;
        axios
          .get(
            `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/settings/additional-info/list/visitor`
          )
          .then((res) => {
            let content = res.data.content;

            this.addInfo = JSON.stringify(content.additionalInfoSettings);

            this.maxVisitor = +content.settingInfoDAO.value;

            this.spinner = false;

            resolve();
          })
          .catch((error) => {
            // alert(error);
            this.spinner = false;

            reject();
            const code = error.response.data.content;

            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }

            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    addVisitor() {
      if (this.visitorList.length == this.maxVisitor) {
        return;
      }

      // Cannot pass this.addInfo to this.visitorList, Vue's will read this.addInfo's value
      let addInfo = this.formatAddInfo();

      this.visitorList.push({ addInfo: addInfo });
    },
    formatAddInfo() {
      const infoList = JSON.parse(this.addInfo);

      return infoList.map((data) => {
        data.value = "";
        data.date = "";
        data.time = "";
        return data;
      });
    },
    removeVisitor(index) {
      this.visitorList.splice(index, 1);
    },
    async onSubmit() {
      // console.log(this.email);
      // console.log("visitorList", this.visitorList);

     if (await this.checkfilledorunfilled() == false){
       console.log("im here");
       return;
       
     }

       console.log("im here 2");


      let message = "";
      let emailPatt = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z.]{2,}$/;

      // if (this.email.length == 0) {
      //   message += `${this.$t("vstr-rsdnt.email")}: ${this.$t(
      //     "vstr-rsdnt.email"
      //   )} ${this.$t("validation.required")}<br>`;
      // } else
      if (this.email) {
        if (this.email.length < 6) {
          message += `${this.$t("vstr-rsdnt.email")}: ${this.$t(
            "validation.minimun"
          )} 10 ${this.$t("validation.characters")}<br>`;
        } else if (!emailPatt.test(this.email)) {
          message += `${this.$t("vstr-rsdnt.email")}: ${this.$t(
            "validation.email"
          )}<br>`;
        }
      }

      const visitorInfoList = this.visitorList.map((data, i) => {
        const addInfoList = data.addInfo
          .map((data2) => {
            // Combine date and time to value //
            if (data2.type == "DATE_TIME") {
              data2.value = `${data2.date} ${data2.time}`;
            }

            // Validate required field //
            if (data2.value == "" && data2.required) {
              message += `${this.$t("vstr-rsdnt.visitor")} ${i + 1}: ${
                data2.label
              } ${this.$t("validation.required")}<br>`;
            } else {
              // Validate type and minlength //
              if (data2.type == "LONG_TEXT") {
                if (data2.value.length >= 1 && data2.value.length < 10) {
                  message += `${this.$t("vstr-rsdnt.visitor")} ${i + 1}: ${
                    data2.label
                  } ${this.$t("validation.minimun")} 10 ${this.$t(
                    "validation.characters"
                  )}<br>`;
                }
              } else if (data2.type == "SHORT_TEXT") {
                if (data2.value.length >= 1 && data2.value.length < 3) {
                  message += `${this.$t("vstr-rsdnt.visitor")} ${i + 1}: ${
                    data2.label
                  } ${this.$t("validation.minimun")} 3 ${this.$t(
                    "validation.characters"
                  )}<br>`;
                }
              }
            }

            // Filter our value == '' //
            if (data2.value == "" && !data2.required) {
              return;
            }

            return {
              id: data2.id,
              ctValue: data2.label,
              value: data2.value,
            };
          })
          .filter((x) => x !== undefined); // Filter undefined //

        return {
          visitorId: i + 1,
          visitorAdditionalInfoDAOList: addInfoList,
        };
      });

      if (message != "") {
        this.showErr = true;
        this.errMsg = message;
        return;
      }

      const body = {
        email: this.email,
        carPlateNumber: this.carPlateNo,
        invitationId: this.id,
        visitorInfoDAOList: visitorInfoList,
      };

      this.loading = true;

      axios
        .post(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/visitor/invitation-registry`,
          body
        )
        .then((res) => {
          this.loading = false;

          this.$router.push({
            path: "/copy-link",
            query: { email: this.email, invitation: res.data.content, invitationId : this.invitation  },
          });
        })
        .catch((error) => {
          // alert(error);
          this.loading = false;
          const code = error.response.data.content;

          if (!code) {
            this.showErr = true;
            this.errMsg = this.$t(`error.code.unknown`);
            return;
          }

          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    },
  },
};
</script>

<style>
h3 {
  margin-bottom: 12px !important;
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.btn-sm {
  padding: 0.1rem 0.3em !important;
}

.icon-size {
  height: 14px !important;
  width: 14px !important;
  min-width: 14px !important;
}

/* .scroll-container {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
} */
</style>