

<template>
  <div>
    <AlertDialog
      :show="showErr"
      type="failed"
      :msg="errMsg"
      @close="showErr = false"
    />

    <HeaderLayout :env="env" :type="0" />

    <b-container class="mt--5">
      <b-row class="justify-content-center">
        <b-col col lg="5" md="7">
          <b-card class="shadow">
            <div class="text-center mb-4">
              <!-- <img
                class="avatar logo"
                :src="logo"
                alt="Logo"
                width="75"
                height="75"
              /> -->

              <img :src="logo" alt="" style="width: 60%" />
            </div>

            <b-form @submit.prevent="onSubmit">
              <div class="text-muted mt-3">
                <h4>{{ $t("entry.title") }}</h4>
              </div>

              <b-form-group>
                <b-form-input
                  v-model="$v.form.pin.$model"
                  @blur="touchedPin"
                  :state="$v.form.pin.$dirty ? validatePin : null"
                  type="password"
                  :placeholder="$t('entry.pin')"
                  maxlength="16"
                  aria-describedby="inv-pin-feedback"
                  trim
                ></b-form-input>

                <b-form-invalid-feedback
                  id="inv-pin-feedback"
                  v-if="$v.form.pin.$dirty"
                  >{{ invPinMsg }}</b-form-invalid-feedback
                >
              </b-form-group>

              <div class="d-flex flex-row mt-4">
                <div class="d-flex flex-column mx-auto">
                  <vue-recaptcha
                    ref="recaptcha"
                    v-model="$v.form.recaptcha.$model"
                    :sitekey="env.RECAPCHA_SITE_KEY"
                    :loadRecaptchaScript="true"
                    @verify="validateRecaptha"
                  ></vue-recaptcha>
                </div>
              </div>

              <div class="mt-4">
                <b-button
                  type="submit"
                  block
                  variant="primary"
                  :disabled="this.$v.form.$invalid || loading"
                >
                  <b-spinner small class="mr-2" v-if="loading"></b-spinner>
                  {{ $t("entry.submitBtn") }}
                </b-button>
              </div>
              <div class="d-flex flex-row mt-3">
                <span class="ml-auto version">v {{this.version}}</span>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>

      <!-- <div class="d-flex flex-row mt-3">
        <div class="d-flex flex-column mx-auto">
          <a class="text-center text-primary" @click="toScan()">{{
            $t("entry.qr-code")
          }}</a>
        </div>
      </div> -->
    </b-container>

    <FooterLayout :env="env" />
  </div>
</template>

<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer>
</script>


<script>
import HeaderLayout from "../../view/HeaderLayout";
import FooterLayout from "../../view/FooterLayout";
import AlertDialog from "../../view/dialog/AlertDialog";
import * as env from  '../../assets/env.json';

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import VueRecaptcha from "vue-recaptcha";
import axios from "axios";

export default {
  components: {
    HeaderLayout,
    FooterLayout,
    VueRecaptcha,
    AlertDialog,
  },
  mixins: [validationMixin],
  data() {
    return {
      siteKey: "",
      form: {
        pin: "",
        recaptcha: "",
      },
      loading: false,

      showSuccess: false,
      successMsg: "",
      showErr: false,
      errMsg: "",

      logo: "",

      type: "",
      invitation: "",
      otp: "",
      email: "",
      unitId: "",
      version: 0,
    };
  },
  created() {
    const info = this.$route.query;

    this.type = info.type;

    // Visitor Info //
    this.invitation = info.invitation;

    // Resident Info //
    this.otp = info.otp;
    this.email = info.email;
    this.unitId = info.unitId;

    this.version = process.env.PACKAGE_VERSION || '0'

    axios
      .get(
        `${this.env.SERVER_URL}/angsaku/multi-unit/settings/admin/system-info?type=SYSTEM_LOGO_1`
      )
      .then((res) => {
        let content = res.data.content[0];

        this.logo = content.value;
      });
  },
  props: {
    env: Object,
  },
  validations: {
    form: {
      pin: { required, minLength: minLength(8) },
      recaptcha: { required },
    },
  },
  computed: {
    validatePin() {
      if (!this.$v.form.pin.required) {
        return false;
      }
      if (!this.$v.form.pin.minLength) {
        return false;
      }
      return true;
    },
    invPinMsg() {
      if (!this.$v.form.pin.required) {
        return `${this.$t("entry.pin")} ${this.$t("validation.required")}`;
      }
      if (!this.$v.form.pin.minLength) {
        return `${this.$t("validation.minimun")} 8 ${this.$t(
          "validation.characters"
        )}`;
      }
      return "";
    },
  },
  methods: {
    toScan() {
      this.$router.push("/scan");
    },
    touchedPin() {
      this.$v.form.pin.$touch();
    },
    validateRecaptha(response) {
      this.$v.form.recaptcha.$model = response;
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        return;
      }

      this.loading = true;

      if (this.type == "visitor") {
        const body = {
          password: this.$v.form.pin.$model,
          reCaptcha: this.$v.form.recaptcha.$model,
          invitationId: this.invitation,
        };

        axios
          .post(
            `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/visitor/invitation`,
            body
          )
          .then((res) => {
            const content = res.data.content;

            this.loading = false;

            this.$router.push({
              path: "/visitor",
              query: { info: JSON.stringify(content) },
            });
          })
          .catch((error) => {
            // alert(error);
            this.$refs.recaptcha.reset();
            this.loading = false;

            const code = error.response.data.content;

            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }

            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      } else if (this.type == "resident") {
        // const body = {
        //   securityPassword: this.$v.form.pin.$model,
        //   recaptcha: this.$v.form.recaptcha.$model,
        //   email: this.email,
        //   unitId: this.unitId,
        //   otp: this.otp
        // };

        const formData = new FormData();
        formData.append("securityPassword", this.$v.form.pin.$model);
        formData.append("recaptcha", this.$v.form.recaptcha.$model);
        formData.append("email", this.email);
        formData.append("unitId", this.unitId);
        formData.append("otp", this.otp);

        axios
          .post(
            `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/user/resident-entry`,
            formData
          )
          .then((res) => {
            const content = res.data.content;

            this.loading = false;

            this.$router.push({
              path: "/resident",
              query: { info: JSON.stringify(content) },
            });
          })
          .catch((error) => {
            this.$refs.recaptcha.reset();
            this.loading = false;

            const code = error.response.data.content;

            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }

            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      }
    },
  },
};
</script>

<style>
.logo {
  width: 75px !important;
  height: 75px !important;
}

.version{
    font-size: 0.9rem;
    font-weight: 500;
    color: #8998ec;
}
</style>